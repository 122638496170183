* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Encode San Expanded", sans-serif;
}
html,
body {
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
